@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --has-zero-width-scroll: true;
}

@layer base {
  /* Global styles here */
  html,
  body {
    height: 100%;
    font-feature-settings: "ss08" on;
  }

  /* Keep in sync with Body1 style in @/text.ts */
  body {
    @apply font-sans font-normal leading-[1.2] tracking-tightest text-[1.125rem];
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .text-shadow {
    text-shadow: -1px -1px 0 var(--tw-shadow-color),
      1px -1px 0 var(--tw-shadow-color), -1px 1px 0 var(--tw-shadow-color),
      1px 1px 0 var(--tw-shadow-color);
  }

  .offset-nav {
    @apply pt-[4.5rem];
  }

  /* Custom input ranges */
  .custom-thumb {
    @apply appearance-none;
  }

  /* Have to separate these per browser for some reason? */
  .custom-thumb::-moz-range-thumb {
    @apply appearance-none outline-8 outline-current border-current h-1 w-1 bg-grey-900 rounded-sm;
    border: 8px solid;
    border-radius: 0;
  }

  .custom-thumb::-webkit-slider-thumb {
    @apply appearance-none outline-8 outline-current border-current h-5 w-5 bg-grey-900 rounded-sm;
    border: 8px solid;
    border-radius: 0;
  }

  @keyframes marquee {
    100% {
      transform: translateX(-100%);
    }
  }

  .animate-marquee {
    animation: marquee 7s linear infinite;
  }
  @media (prefers-reduced-motion) {
    .animate-marquee {
      animation: none;
    }
  }
}
