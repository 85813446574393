@font-face {
  font-family: "ABC Gravity";
  src: url("../../public/fonts/ABCGravity-Compressed.woff2") format("woff2"),
    url("../../public/fonts/ABCGravity-Compressed.woff") format("woff"),
    url("../../public/fonts/ABCGravity-Compressed.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../public/fonts/MabryPro.woff2") format("woff2"),
    url("../../public/fonts/MabryPro.woff") format("woff"),
    url("../../public/fonts/MabryPro.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../public/fonts/MabryProMedium.woff2") format("woff2"),
    url("../../public/fonts/MabryProMedium.woff") format("woff"),
    url("../../public/fonts/MabryProMedium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../../public/fonts/MabryProBold.woff2") format("woff2"),
    url("../../public/fonts/MabryProBold.woff") format("woff"),
    url("../../public/fonts/MabryProBold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("../../public/fonts/IBMPlexMono-Regular.woff2") format("woff2"),
    url("../../public/fonts/IBMPlexMono-Regular.woff") format("woff"),
    url("../../public/fonts/IBMPlexMono-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("../../public/fonts/IBMPlexMono-Medium.woff2") format("woff2"),
    url("../../public/fonts/IBMPlexMono-Medium.woff") format("woff"),
    url("../../public/fonts/IBMPlexMono-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("../../public/fonts/IBMPlexMono-Bold.woff2") format("woff2"),
    url("../../public/fonts/IBMPlexMono-Bold.woff") format("woff"),
    url("../../public/fonts/IBMPlexMono-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
